
import { Component, Prop, Vue } from "vue-property-decorator";
import { JourneyItem } from "@/models/Journey";
import draggable from "vuedraggable";
import _ from "underscore";

type CustomGridItemData = GridItemData &
  NonNullable<JourneyItem["steps"]>[number];

@Component({
  components: {
    draggable,
  },
})
export default class DraggableJourneySteps extends Vue {
  @Prop({ default: () => [] }) steps!: NonNullable<JourneyItem["steps"]>;

  removeItem(i: string) {
    this.steps.splice(Number.parseInt(i), 1);
  }
  reorderSteps(a, b, c) {
    const steps = _.groupBy(this.steps, "active");
    _.each(steps.true, (s, i) => {
      s.order = i;
    });
    _.each(steps.false, (s, i) => {
      s.order = null;
    });
  }
}
