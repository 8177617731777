
import _ from "underscore";
import { Component, Mixins, Prop, Watch, Vue } from "vue-property-decorator";
import AddJourneyDrawer from "./AddJourneyDrawer.vue";
import { patchJourney, postJourneySteps } from "@/api/tharbad";
import { JourneyItem } from "@/models/Journey";
import Notify from "@/utils/notifications";

function omitOptionals<T>(item: T) {
  return _.omit(item, "id", "created-at", "updated-at", "steps");
}

@Component
export default class EditJourneyDrawer extends Mixins(Vue, AddJourneyDrawer) {
  @Prop({ default: null }) item!: JourneyItem & {
    id: number;
    steps: NonNullable<JourneyItem["steps"]>;
  };

  processItem() {
    this.validateForm()
      .then(async isValid => {
        if (this.item !== null && isValid) {
          try {
            this.toggleLoading(true);
            await patchJourney(this.item.id, omitOptionals(this.item));
            await postJourneySteps(
              this.item.id,
              this.item.steps.map(x => omitOptionals(x))
            );
            Notify.successful("Recorrido actualizado exitosamente.");
            this.$emit("updateView");
            this.closeDrawer();
          } catch (e) {
            if (e.data === undefined) {
              Notify.error((e as Error).message);
            } else {
              Notify.gebServerError(e as Error);
            }
          }
        }
      })
      .finally(() => {
        this.toggleLoading(false);
      });
  }

  closeDrawerEdit() {
    if (this.item !== null) {
      Object.assign(this.item, this.itemData);
    }
    this.closeDrawer();
  }

  created() {
    if (this.item === null || !Array.isArray(this.item.steps)) {
      this.fullscreenLoading = true;
    }
  }

  beforeUpdate() {
    if (
      this.fullscreenLoading &&
      this.item !== null &&
      Array.isArray(this.item.steps)
    ) {
      this.fullscreenLoading = false;
    } else if (
      !this.fullscreenLoading &&
      (this.item === null || !Array.isArray(this.item.steps))
    ) {
      this.fullscreenLoading = true;
    }
  }

  @Watch("show")
  copyItemValue() {
    if (this.show) {
      Object.assign(this.itemData, this.item);
    }
  }
}
