
import { Component, Prop, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import { JourneyItem } from "@/models/Journey";

@Component({ components: {} })
export default class JourneyStepFormDrawer extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: "Nuevo paso" }) title!: string;
  @Prop({ default: [] }) steps!: NonNullable<JourneyItem["steps"]>;

  fullscreenLoading = false;
  step = {
    content: "",
    sender: "",
    active: true,
  };

  rules = {
    content: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    sender: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };
  get isVisible() {
    return this.show;
  }
  private getForm() {
    return this.$refs["form"] as HTMLFormElement;
  }
  closeDrawer() {
    const form = this.getForm();
    form.resetFields();
    form.clearValidate();
    this.$emit("closeStepsDrawer", false);
  }
  async validateForm() {
    const form = this.getForm();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      form.validate((valid: boolean) => {
        resolve(valid);
      });
    });
  }
  processItem() {
    this.validateForm()
      .then(async isValid => {
        if (isValid) {
          try {
            this.fullscreenLoading = true;
            this.$emit("updateSteps", {
              ...this.step,
              order: -1,
            });
            Notify.successful("Paso creado exitosamente.");
            this.closeDrawer();
          } catch (e) {
            Notify.gebServerError(e as Error);
          }
        }
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }
}
