
import _ from "underscore";
import { Component, Vue } from "vue-property-decorator";
import {
  deleteJourney,
  findJourneys,
  findJourneySteps,
  Payload,
} from "@/api/tharbad";
import * as CountryService from "@/api/helpers/Country";
import DeletePopover from "@/components/DeletePopover.vue";
import AddJourneyDrawer from "@/components/sms/AddJourneyDrawer.vue";
import EditJourneyDrawer from "@/components/sms/EditJourneyDrawer.vue";
import JourneyStepsExpandTable from "@/components/sms/JourneyStepsExpandTable.vue";
import {
  bool2IconFilter,
  countryCode2FullNameFilter,
  shipmentMaxSizeFilter,
} from "@/filters";
import { CatalogItem } from "@/models/Catalog";
import { JourneyWithSteps } from "@/models/Journey";
import Notify from "@/utils/notifications";

interface SortParams {
  columns: Record<string, unknown>;
  order: string | null;
  prop: string;
}
interface Params {
  limit: number;
  offset: number;
  query: {
    sender?: string;
    content?: string;
    "country-code"?: string;
    name?: string;
    sort?: string;
  };
}
@Component({
  components: {
    DeletePopover,
    JourneyStepsExpandTable,
    AddJourneyDrawer,
    EditJourneyDrawer,
  },
  filters: {
    bool2IconFilter,
    shipmentMaxSizeFilter,
    countryCode2FullNameFilter,
  },
})
export default class SMSJourneyView extends Vue {
  fullscreenLoading = true;
  showAddDrawer = false;
  showEditDrawer = false;
  currentPage = 1;
  pageNumberOfElementsOptions = [10, 25, 50, 100, 250, 500, 1000];
  defaultParams = {
    query: {
      name: undefined,
      sender: undefined,
      content: undefined,
      "country-code": undefined,
      sort: "-created-at",
    },
    limit: 10,
    offset: 0,
  };
  params: Params = _.clone(this.defaultParams);
  currentItem: JourneyWithSteps | null = null;
  items = {} as Payload<JourneyWithSteps>;
  countries: CatalogItem<string>[] = [];

  async deleteCard(id: number) {
    this.fullscreenLoading = true;
    try {
      const itemId = this.items.rows[id].id;
      await deleteJourney(itemId);
      await this.getItems();
      Notify.successful(`Recorrido ${itemId} eliminado exitosamente.`);
    } catch (e) {
      Notify.gebServerError(e);
    }
    this.fullscreenLoading = false;
  }

  openAddDrawer() {
    this.showAddDrawer = true;
  }

  closeAddDrawer(value: boolean) {
    this.showAddDrawer = value;
    this.currentItem = null;
  }

  closeEditDrawer(value: boolean) {
    this.showEditDrawer = value;
    this.currentItem = null;
  }

  async editJourney(index: number) {
    this.currentItem = null;
    this.showEditDrawer = true;
    if (
      this.items.rows[index].steps === undefined ||
      this.items.rows[index].steps?.length === 0
    ) {
      this.items.rows[index].steps = await findJourneySteps(
        this.items.rows[index].id
      );
    }
    this.currentItem = { ...this.items.rows[index] };
  }

  toggleStepsIcon(journeyId: number, flag: boolean) {
    if (document) {
      const items = ["arrow-right", "loading"];
      let choices = [1, 0];
      if (flag) {
        choices = [0, 1];
      }
      const els = document.querySelectorAll(
        `.el-table__row.expanded td i.el-icon-${items[choices[0]]}`
      );
      for (const el of els) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          //@ts-ignore
          const tdEl = el.parentElement.parentElement.parentElement;
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          //@ts-ignore
          const journeyIdCell = tdEl.nextElementSibling.querySelector(
            "div.cell"
          ).innerText;
          if (journeyIdCell.toString() === journeyId.toString()) {
            el.className = el.className.replace(
              items[choices[0]],
              items[choices[1]]
            );
            if (choices[0] === 1 && el.parentElement) {
              setTimeout(
                iEl => {
                  iEl.click();
                },
                50,
                el
              );
            }
          }
        } catch (e) {
          /** */
        }
      }
    }
  }

  async sortTable(params: SortParams) {
    if (params.order === null) {
      this.params.query.sort = undefined;
    } else {
      this.params.query.sort = `${params.order === "descending" ? "-" : "+"}${
        params.prop
      }`;
    }
    await this.getItems();
  }

  async getItems(page = this.currentPage) {
    this.currentPage = page;
    this.fullscreenLoading = true;
    this.params.offset = (page - 1) * this.params.limit;
    this.items = await findJourneys({
      sort: this.params.query.sort,
      limit: this.params.limit,
      offset: this.params.offset,
    });
    this.fullscreenLoading = false;
  }

  created() {
    const index = "6.2";
    const title = "Recorridos de SMS";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "SMS",
        to: {
          name: "sms-campaign",
        },
      },
      {
        text: "Recorridos",
      },
    ]);
    Promise.all([
      CountryService.findCountryCodes().then(data => {
        this.countries = data.map(x => {
          return { id: x.countryCode, description: x.description };
        });
      }),
      this.getItems(),
    ]);
  }
}
